<template>
	<div class="box box_mm">
		<div class="box_main">
			<div class="search_box">
				<div class="search_item">
					<span style="width: 0.6rem;">名称：</span>
					<el-input v-model="searchinput" placeholder="请输入名称" style="width: 2rem;"></el-input>
				</div>
				<div class="search_item">
					<el-button type="primary" @click="search">搜索</el-button>
					<el-button type="success" @click="addtype">新增</el-button>
				</div>
			</div>
			<div class="box_cont">
				<div class="btn_list">
				</div>
				<div style="height: 76vh;box-sizing: border-box;">
					<MyTable ref="zjlist" :tableData="tableData" :tableHeader="tableHeader" :showbm="true"
						:czwidth="150" @getdata="gettable">
						<template slot="btns" slot-scope="{ scope }">
							<el-button @click="edit(scope.row)" type="warning" size="mini">修改</el-button>
							<el-button @click="deleterow(scope.row)" type="danger" size="mini">删除</el-button>
						</template>
					</MyTable>
				</div>
			</div>
		</div>


		<!--新增 编辑-->
		<el-dialog :title="isadd?'新增活动分类':'编辑活动分类'" :visible.sync="showdetail" :before-close="closedb" width="30%">
			<div style="width: 100%;">
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
					<el-form-item label="名称" prop="name">
						<el-input v-model="ruleForm.name"></el-input>
					</el-form-item>
					<el-form-item label="排序">
						<el-input v-model="ruleForm.weigh" type="number"></el-input>
					</el-form-item>
				</el-form>
			</div>

			<span style="width: 100%;display: flex;align-items: center;justify-content: flex-end;margin-top: 20px;">
				<el-button @click="closedb" size="small">取消</el-button>
				<el-button type="success" @click="qrdb()" size="small">确定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				showdetail: false,
				detail_id: '',
				searchinput: '',
				tableHeader: [{
						name: '名称',
						prop: 'name',
						width: ''
					},
					{
						name: '排序',
						prop: 'weigh',
						width: ''
					},
					{
						name: '创建时间',
						prop: 'createtime',
						width: ''
					}
				],
				tableData: [],
				ruleForm: {
					name: '',					
					weigh: '',
				},
				rules: {
					name: [{
						required: true,
						message: '请输入名称',
						trigger: 'blur'
					}]
				},
				isadd:true
			}
		},
		mounted() {
			this.gettable()
		},
		methods: {
			addtype(){
				this.isadd = true
				this.showdetail = true
			},
			qrdb() {
				this.$refs.ruleForm.validate((valid) => {
					if (valid) {
						this.$post({
							url: this.isadd?'/api/activity_classify/add':'/api/activity_classify/edit',
							params: this.ruleForm
						}).then((res) => {
							console.log(res)
							this.$message.success('操作成功')
							this.closedb()
							this.gettable()
						})
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			edit(item) {
				this.isadd = false
				this.ruleForm = JSON.parse(JSON.stringify(item))  
				this.showdetail = true
			},
			closedb() {
				this.ruleForm={
					name: '',
					weigh: '',
				}
				this.showdetail = false
			},
			reset() {
				this.searchinput = ''
				this.$refs.zjlist.inputpage = 1
				this.gettable()
			},
			search() {
				this.$refs.zjlist.inputpage = 1
				this.gettable()
			},
			gettable() {
				this.$post({
					url: '/api/activity_classify/index',
					params: {
						name:this.searchinput,
						p: this.$refs.zjlist.inputpage,
						size: this.$refs.zjlist.pagesize
					}
				}).then((res) => {
					
					this.tableData = res
					this.$refs.zjlist.total = res.count
				})
			},
			deleterow(row) {
				this.$confirm('确认删除该条数据吗？')
					.then(_ => {
						this.$post({
							url: '/api/activity_classify/del',
							params: {
								id: row.id
							}
						}).then((res) => {
							this.$message.success('删除成功')
							this.gettable()
						})
					})
					.catch(_ => {});

			},
			showsetrole(row) {
				console.log(row)
				this.$refs.mymx.showmx = true
			},
			changswitch(row) {
				console.log(row)
			},
		}
	}
</script>

<style lang="scss" scoped>
	.box_main {
		background-color: #fff;
		padding: 15px 25px;
		width: 100%;
		box-sizing: border-box;
		min-height: 100%;
	}

	.search_box {
		display: flex;
		align-items: center;
		font-size: 0.16rem;

		.search_item {
			display: flex;
			align-items: center;
			margin-right: 0.8rem;

		}
	}

	.box_cont {
		margin-top: 20px;
	}

	.btn_list {
		display: flex;
		align-items: center;
	}

	.tc_title {
		font-size: 0.18rem;
		font-weight: bold;
		color: #333;
		position: relative;
		padding-left: 20px;
		margin-bottom: 20px;
	}

	.tc_title:after {
		content: '';
		position: absolute;
		height: 100%;
		width: 4px;
		left: 0px;
		background-color: rgb(26, 144, 253);
	}

	::v-deep td {
		text-align: center !important;
	}

	::v-deep .el-table th.el-table__cell>.cell {
		text-align: center !important;
	}

	::v-deep .el-table th.el-table__cell.is-leaf {
		background-color: rgb(242, 242, 242);
		color: #777;
	}

	::v-deep .el-table th.el-table__cell.is-leaf,
	.el-table td.el-table__cell {}

	::v-deep .el-table td.el-table__cell {}

	::v-deep .el-table {
		margin-top: 10px;

	}

	::v-deep .el-table__fixed {}

	::v-deep .el-table::before {
		background-color: #fff !important;
	}

	::v-deep .el-table__fixed::before {
		background-color: #fff !important;
	}

	::v-deep .el-dialog__header {
		display: flex !important;
	}

	::v-deep .el-dialog__footer span {
		display: flex !important;
		justify-content: space-around;
		width: 100%;
	}
</style>